<template>
  <v-card>
    <v-card-title>
      {{ "Alerts" }}
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-card-text>
      <v-alert text color="info">
        <v-row align="center" no-gutters>
          <v-col cols="12" class="grow">
            <v-chip class="ma-2" color="info" label text-color="white"></v-chip>
            information to be aware about
          </v-col>
          <v-col cols="12" class="grow">
            <v-chip class="ma-2" color="error" label text-color="white"></v-chip>
            When something is wrong or past due
          </v-col>
          <v-col cols="12" class="grow">
            <v-chip class="ma-2" color="warning" label text-color="white"></v-chip>
            When something is not critical but can and will be if no action is taken
          </v-col>
          <v-col cols="12" class="grow text-center py-1">
            <v-divider></v-divider>
            When the alert is unread, the checkbox is uncheck. To mark the alert as read. the checkbox must be checked
          </v-col>
        </v-row>
      </v-alert>
      <v-data-table
        :headers="headers"
        :items="browse"
        :search="search"
        :loading="loading"
        loading-text="LOADING.... PLEASE WAIT"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.index="{ item }">
          {{ item.index }}
        </template>

        <template #[`item.status`]="{item}">
          <v-chip class="ma-2" :color="item.status" label text-color="white"></v-chip>
        </template>

        <template #[`item.read`]="{item}">
          <v-switch v-model="item.read" inset :label="``" @change="markAsRead(item)"></v-switch>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-btn depressed text color="primary" @click="read(item)">
            <v-icon left dark>
              {{ icons.mdiEmailNewsletter }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="reader" max-width="720px">
      <v-card class="pa-15">
        <v-card-text class="pb-0">
          <p class="text-h4 text--primary">
            {{ alertitem.model }}
          </p>
          <p>
            {{ alertitem.message }}
          </p>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn text color="teal accent-4" @click="reveal = false">
            {{ alertitem.created_at }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mdiEyeOutline, mdiEmailNewsletter } from "@mdi/js";
import permission from "@/directive/permission"; // Permission directive (v-permission)
import Request from "@/core/api/request";
import util from "@/core/utils/misc";

export default {
  name: "AlertBrowse",
  directives: { permission },
  data() {
    return {
      loading: true,
      search: "",
      browse: [],
      headers: [
        { text: "NO.", value: "index", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Model", value: "model", sortable: true },
        { text: "Read", value: "read", sortable: true },
        { text: "Date", value: "created_at", sortable: true },
        { text: "Action.", value: "actions", sortable: false },
      ],
      reader: false,
      alertitem: {},
      icons: {
        mdiEyeOutline,
        mdiEmailNewsletter,
      },
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    updateBrownse() {
      this.initialize();
    },
    async initialize() {
      const { data, status } = await Request.get(`${this.$IYA.URI.ALERT__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        this.loading = false;

        return;
      }
      if (data.data.length < 1) {
        this.loading = false;

        return;
      }
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.browse = data.data;
      this.loading = false;
    },

    read(param) {
      this.alertitem = param;
      this.reader = true;
      this.markAsRead(param);
    },

    async markAsRead(item) {
      util.isLoading({ active: true, title: "Wait while we process." });
      await Request.update(`${this.$IYA.URI.ALERT__MARK}`, item);
      await this.$store.dispatch(`${this.$IYA.NAMESPACE.SYS}/${this.$IYA.ACTION.ALERTS}`);
      this.initialize();
      util.isLoading();
    },

    // trash(id) {
    //   this.$swal({
    //     title: `Are you sure?`,
    //     text: `You can't revert your action`,
    //     type: `warning`,
    //     showCancelButton: true,
    //     confirmButtonText: `Yes Delete it!`,
    //     cancelButtonText: `No, Keep it!`,
    //     showCloseButton: true,
    //     showLoaderOnConfirm: true,
    //   }).then(result => {
    //     if (result.value) {
    //       this.destroy(id);
    //     } else {
    //       this.$swal(`Cancelled`, `Your records are still intact`, `info`);
    //     }
    //   });
    // },

    // async destroy(id) {
    //   await Request.destroy(`${this.$IYA.URI.ALERT__DELETE}`, { id: id }).then(response => {
    //     if (response.data.success) {
    //       this.$swal(`Deleted`, `You successfully deleted this record`, `success`);
    //       this.initialize();
    //     } else {
    //       this.$swal(`Failed !`, `Failed to remove record`, `error`);
    //     }
    //   });
    // },
  },
};
</script>
