var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s("Alerts")+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-alert',{attrs:{"text":"","color":"info"}},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"grow",attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"info","label":"","text-color":"white"}}),_vm._v(" information to be aware about ")],1),_c('v-col',{staticClass:"grow",attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"error","label":"","text-color":"white"}}),_vm._v(" When something is wrong or past due ")],1),_c('v-col',{staticClass:"grow",attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning","label":"","text-color":"white"}}),_vm._v(" When something is not critical but can and will be if no action is taken ")],1),_c('v-col',{staticClass:"grow text-center py-1",attrs:{"cols":"12"}},[_c('v-divider'),_vm._v(" When the alert is unread, the checkbox is uncheck. To mark the alert as read. the checkbox must be checked ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.browse,"search":_vm.search,"loading":_vm.loading,"loading-text":"LOADING.... PLEASE WAIT"},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.status,"label":"","text-color":"white"}})]}},{key:"item.read",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","label":""},on:{"change":function($event){return _vm.markAsRead(item)}},model:{value:(item.read),callback:function ($$v) {_vm.$set(item, "read", $$v)},expression:"item.read"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","text":"","color":"primary"},on:{"click":function($event){return _vm.read(item)}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEmailNewsletter)+" ")])],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"720px"},model:{value:(_vm.reader),callback:function ($$v) {_vm.reader=$$v},expression:"reader"}},[_c('v-card',{staticClass:"pa-15"},[_c('v-card-text',{staticClass:"pb-0"},[_c('p',{staticClass:"text-h4 text--primary"},[_vm._v(" "+_vm._s(_vm.alertitem.model)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.alertitem.message)+" ")])]),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-btn',{attrs:{"text":"","color":"teal accent-4"},on:{"click":function($event){_vm.reveal = false}}},[_vm._v(" "+_vm._s(_vm.alertitem.created_at)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }